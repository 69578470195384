import React from "react"
import { StaticQuery, graphql } from "gatsby"

const SPREADSHEET_QUERY = graphql`
  query listQuery {
    allGoogleSpreadsheetListList {
      edges {
        node {
          id
          word
          meaning
          slug
        }
      }
    }
  }
`

const withData = WrappedComponent =>
  class extends React.Component {
    render() {
      return (
        <StaticQuery
          query={SPREADSHEET_QUERY}
          render={data => (
            <WrappedComponent
              {...this.props}
              data={data.allGoogleSpreadsheetListList.edges}
            />
          )}
        />
      )
    }
  }
export default withData
